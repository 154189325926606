import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import RouterLink from '@shared/ui/navigation/RouterLink';
import CounterBadge from '@shared/ui/badges/CounterBadge';
import IconText from '@shared/ui/icons/IconText';
const SubItemHeight = 40;
const SubItemWidth = 195;
const RouterLinkStyled = styled(RouterLink)(props => ({
    width: '100%',
    padding: `${props.theme.spacing_sizes.xs}px ${props.theme.spacing_sizes.s}px
              ${props.theme.spacing_sizes.xs}px ${props.theme.spacing_sizes.m}px`,
    textDecoration: 'none',
}));
const SubItemIconText = styled(IconText, { shouldForwardProp: propName => propName !== 'collapsed' })(props => ({
    '& .MuiTypography-root': {
        maxWidth: props.collapsed ? 0 : SubItemWidth,
        transition: 'max-width 0.25s',
    },
}));
const defineSelectedStyles = (theme, selected, collapsed) => (selected && !collapsed ? ({
    borderColor: theme.palette.primary.main,
    [`${SubItemIconText}`]: {
        color: theme.palette.primary.main,
    },
    '&:hover': {
        backgroundColor: '#F8FAFF',
    },
    '&:active': {
        borderColor: theme.palette.primary.main,
        [`${SubItemIconText}`]: {
            color: theme.palette.primary.main,
        },
    },
}) : ({}));
const CounterBadgeContainer = styled.div((props) => ({
    position: 'absolute',
    display: 'inline',
    right: '0%',
    overflow: 'hidden',
    visibility: props.collapsed ? 'hidden' : 'visible',
    opacity: props.collapsed ? 0 : 1,
    maxHeight: props.collapsed ? 0 : 20,
    transition: 'max-height 0.25s, opacity 0.3s',
}));
const Container = styled('li', { shouldForwardProp: (propName) => propName !== 'selected' && propName !== 'collapsed' })(props => (Object.assign({ display: 'flex', width: '100%', height: '100%', flexDirection: 'row', position: 'relative', boxSizing: 'border-box', cursor: 'pointer', maxWidth: props.collapsed ? 0 : SubItemWidth, maxHeight: props.collapsed ? 0 : SubItemHeight, alignItems: 'center', justifyContent: 'space-between', color: '#7E868C', borderLeft: '2px solid #DFE3E6', transition: 'max-height 0.25s, max-width 0.25s', [`${SubItemIconText}`]: {
        color: '#687076',
    }, '&:hover': {
        backgroundColor: '#F8F9FA',
        [`${SubItemIconText}`]: {
            color: '#687076',
        },
    }, '&:active': !props.collapsed ? {
        backgroundColor: '#EFF1F3',
    } : undefined }, defineSelectedStyles(props.theme, props.selected, props.collapsed))));
const SidebarSubItem = ({ Icon = undefined, text, selected = undefined, collapsed = undefined, onClick = undefined, badge = undefined, href = '', }) => {
    // we suppose that behavior would be similar for click and space\enter press
    const handlePress = (ev) => {
        if (onClick) {
            ev.preventDefault();
            onClick();
        }
    };
    return (_jsxs(Container, { "aria-hidden": collapsed, selected: selected, collapsed: collapsed, onClick: handlePress, children: [(Icon || text) && (_jsx(RouterLinkStyled, { to: href, "aria-hidden": collapsed, tabIndex: collapsed ? -1 : 0, children: _jsx(SubItemIconText, { text: text, IconComponent: Icon, collapsed: collapsed }) })), badge && (_jsx(CounterBadgeContainer, { collapsed: collapsed, children: _jsx(CounterBadge, { text: badge }) }))] }));
};
export default SidebarSubItem;
