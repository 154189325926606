import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import CounterBadge from '@shared/ui/badges/CounterBadge';
import IconText from '@shared/ui/icons/IconText';
import { ContainerRow } from '@src/components/styled';
const IconTextStyled = styled(IconText)();
const SubItemWidth = 195;
const defineSelectedStyles = (theme, selected) => (selected ? ({
    borderColor: theme.palette.primary.main,
    [`${IconTextStyled}`]: {
        color: theme.palette.primary.main,
    },
    '&:hover': {
        backgroundColor: '#F8FAFF',
    },
    '&:active': {
        borderColor: theme.palette.primary.main,
        [`${IconTextStyled}`]: {
            color: theme.palette.primary.main,
        },
    },
}) : ({}));
const CounterBadgeContainer = styled.div(() => ({
    position: 'absolute',
    right: '0%',
    overflow: 'hidden',
}));
const HoverSubItemContainer = styled(ContainerRow, { shouldForwardProp: (propName) => propName !== 'selected' && propName !== 'collapsed' })(props => (Object.assign({ position: 'relative', boxSizing: 'border-box', cursor: 'pointer', width: '100%', height: '100%', padding: `${props.theme.spacing_sizes.xs}px ${props.theme.spacing_sizes.s}px
              ${props.theme.spacing_sizes.xs}px ${props.theme.spacing_sizes.m}px`, alignItems: 'center', justifyContent: 'space-between', color: '#7E868C', borderLeft: '2px solid #DFE3E6', [`${IconTextStyled}`]: {
        color: '#687076',
    }, '&:hover': {
        backgroundColor: '#F8F9FA',
        [`${IconTextStyled}`]: {
            color: '#687076',
        },
    }, '&:active': {
        backgroundColor: '#EFF1F3',
    } }, defineSelectedStyles(props.theme, props.selected))));
const HoverMenuSubItem = ({ Icon = undefined, text, selected = undefined, collapsed = undefined, onClick = undefined, badge = undefined, }) => {
    const handleClick = (event) => {
        event.stopPropagation();
        if (onClick) {
            onClick();
        }
    };
    return (_jsxs(HoverSubItemContainer, { selected: selected, collapsed: collapsed, onClick: handleClick, children: [(Icon || text) && (_jsx(IconTextStyled, { text: text, IconComponent: Icon })), badge && (_jsx(CounterBadgeContainer, { children: _jsx(CounterBadge, { text: badge }) }))] }));
};
export default HoverMenuSubItem;
